import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import cx from 'classnames';
import ss from './Upload.module.scss';
import UploadTipSocial from './UploadTipSocial';
import Select from '@/atom/controls/Select';
import useSocial from '@/hook/useSocialService';
import { IFileData, StakeHolders } from '@/model/Types';
import useDataProcess from '@/hook/useDataProcess';
import useGptService from '@/hook/useGptService';
import useCheckout from '@/hook/useCheckout';
import useLoading from '@/hook/useLoading';
import { IResponse } from '@/model/Responses';
import { useNavigate } from 'react-router-dom';
import Label from '@/atom/controls/Label';
import { useAuthContext } from '../authcheck/AuthCheck';
import useStoreService from '@/hook/useStoreService';

const extractYouTubeVideoId = (url: string): string | null => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|live\/|shorts\/|v\/|e\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const extractTikTokPostId = (url: string): string | null => {
  const regex = /tiktok\.com\/@[\w.-]+\/video\/(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const extractFacebookPostId = (url: string): string | null => {
  const regex =
    /(?:posts|videos|photo\.php|photo\/\?fbid|permalink\.php|story\.php|pfbid|share\/p|groups\/[^/]+\/permalink)(?:\/|=)([0-9A-Za-z]+)/;
  const match = url.match(regex);

  // Special case for story_fbid and fbid
  const storyMatch = url.match(/story_fbid=([0-9A-Za-z]+)/);
  const fbidMatch = url.match(/[?&]fbid=([0-9A-Za-z]+)/);

  return match
    ? match[1]
    : storyMatch
    ? storyMatch[1]
    : fbidMatch
    ? fbidMatch[1]
    : null;
};

const Social: React.FC = () => {
  const nav = useNavigate();
  const { user } = useAuthContext();
  const { getYoutubeComments, getTiktokComments, getFacebookComments } =
    useSocial();
  const { splitChunks, calculateTime } = useDataProcess();
  const { getLimitPerFile } = useGptService();
  const { getCost } = useCheckout();
  const { createJob } = useStoreService();
  const { startLoading, stopLoading } = useLoading();
  const [totalRows, setTotalRows] = useState<number>();
  const [totalTokens, setTotalTokens] = useState<number>();
  const [totalMinutes, setTotalMinutes] = useState<number>();
  const [totalSeconds, setTotalSeconds] = useState<number>();
  const [cost, setCost] = useState<number>();
  const [data, setData] = useState<IFileData | null>();
  const [platform, setPlatform] = useState('');
  const [quantity, setQuantity] = useState('');
  const [postUrl, setPostUrl] = useState('');
  const [response, setResponse] = useState<IResponse>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [orderType, setOrderType] = useState<string>('');

  const fetchCost = async (tokens: number) => {
    const res = await getCost(tokens);
    setCost(res);
  };

  useEffect(() => {
    if (data && data.isValid) {
      const total = calculateTime([data]);
      setTotalRows(total.totalRows);
      setTotalTokens(total.totalTokens);
      setTotalMinutes(total.totalMinutes);
      setTotalSeconds(total.totalSeconds);
      fetchCost(total.totalTokens);
    }
  }, [data]);

  const handlePlatformChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPlatform(event.target.value);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setQuantity(event.target.value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setOrderType(value);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostUrl(event.target.value);
  };

  const getFileTotalTokens = (dataFile: IFileData) => {
    let total = 0;
    let rows = 0;
    dataFile.chunks.forEach((chunk) => {
      total += chunk.totalTokens;
      rows += chunk.data.length;
    });
    return { totalTokens: total, totalRows: rows };
  };

  const clearData = () => {
    setData(null);
    setPostUrl('');
    setPlatform('');
    setTotalRows(0);
    setTotalTokens(0);
    setTotalMinutes(0);
    setTotalSeconds(0);
    setCost(0);
  };

  const handleSubmit = async () => {
    setData(null);
    let id = '';
    let numberOfComment = 0;
    if (platform === 'youtube') {
      const videoId = extractYouTubeVideoId(postUrl);
      id = videoId || '';
    }

    if (platform === 'tiktok') {
      const postId = extractTikTokPostId(postUrl);
      id = postId || '';
    }

    if (platform === 'facebook') {
      const postId = extractFacebookPostId(postUrl);
      id = postId || '';
    }

    if (!quantity || quantity === '') {
      alert('Please select a quantity of comments to fetch');
      return;
    }

    if (id === '') {
      alert(
        "It appears the file isn't from the correct platform; please update your entry and try again",
      );
      return;
    }

    numberOfComment = parseInt(quantity);

    startLoading('fetching comments...');
    let data: string[] = [];
    let title: string = platform;

    if (platform === 'youtube') {
      const res = await getYoutubeComments(id, numberOfComment);
      console.log(res);
      if (res.status) {
        data = res.data.comments;
        title = res.data.title;
      }
    } else if (platform === 'tiktok') {
      const res = await getTiktokComments(id, postUrl, numberOfComment);
      if (res.status) {
        data = res.data.comments;
        title = res.data.title;
      }
    } else if (platform === 'facebook') {
      const res = await getFacebookComments(id, numberOfComment);
      if (res.status) {
        data = res.data.comments;
        title = res.data.title;
      }
    }

    const fData = data
      .filter((it) => it != null && it != '')
      .map((it, ind) => {
        return {
          id: ind,
          text: it,
        };
      });
    let dataFile: IFileData = { id: id, source: platform } as IFileData;
    dataFile.id = id;
    dataFile.fileName = title;
    dataFile.chunks = await splitChunks(fData);
    dataFile.chunks.forEach((v) => {
      dataFile.totalRows += v.data.length;
    });
    dataFile.showMessage = true;
    const total = getFileTotalTokens(dataFile);
    dataFile.totalTokens = total.totalTokens;
    dataFile.totalRows = total.totalRows;

    if (total.totalTokens > getLimitPerFile()) {
      dataFile.isValid = false;
      dataFile.message =
        'Because of a token limit, processing this file is not possible as it is quite large. Consider breaking it into smaller segments or uploading a more compact file.';
    } else {
      dataFile.isValid = true;
    }

    setData(dataFile);

    console.log('Submitting:', { platform: platform, id: id });
    stopLoading();
  };

  const submitCommand = async (excuteType: boolean) => {
    if (!orderType) {
      alert('please select stakeholder category');
      return;
    }
    if (!data || (data && !data.isValid)) {
      alert('Please submit a valid file');
      return;
    }

    if (data && data.isValid && data.totalRows === 0) {
      alert('Can not fetch comment from the URL');
      return;
    }
    const message = `The process could require approximately ${totalMinutes} minutes ${totalSeconds} seconds. Would you like to ${
      excuteType
        ? 'set a schedule for this order? You will receive an email notification once the order is finished.'
        : 'continue placing your order?'
    }?`;
    // eslint-disable-next-line no-restricted-globals
    if (confirm(message) === true) {
      const { id, ...content } = data;
      startLoading('Analyzing');
      setDisableButton(true);
      const res = await createJob([content], excuteType, orderType);
      stopLoading();
      setDisableButton(false);
      if (res.status) {
        setData(null);
        if (user && user.paymentMethodId) {
          nav(`/checkout/${res.data.orderId}`);
        } else {
          nav(`/payment/${res.data.orderId}`);
        }
      }
    }
  };

  const download = async () => {
    if (!data || (data && !data.isValid)) return;

    // Prepare the data for the Excel sheet (without headers)
    const rows: string[][] = [];
    data?.chunks.forEach((chunk) => {
      chunk.data.forEach((item) => {
        rows.push([item.text]); // Each row contains only the comment text
      });
    });

    // Create a worksheet without headers
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Create a Blob and initiate the download
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${data?.fileName || 'export'}.xlsx`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div style={{ padding: '30px' }}>
      <p className={ss['title']}>Social link:</p>
      <div className='flex gap-5 my-4'>
        <div className='flex flex-col gap-3 w-[640px]'>
          <div className='flex gap-4 items-center'>
            <label className='w-48'>Social Media Platform</label>
            <Select
              className='flex-1'
              value={platform}
              onChange={handlePlatformChange}
            >
              <option value='' disabled hidden>
                Select a platform
              </option>
              <option value='youtube'>Youtube</option>
              <option value='facebook'>Facebook</option>
              <option value='instagram'>Instagram</option>
              <option value='tiktok'>Tiktok</option>
              <option value='twitter'>X (Twitter)</option>
              <option value='linkedin'>LinkedIn</option>
            </Select>
          </div>
          <div className='flex gap-4 items-center'>
            <label className='w-48'>Comment quantity</label>
            <Select
              className='flex-1'
              value={quantity}
              onChange={handleQuantityChange}
            >
              <option value='' disabled hidden>
                Select a quantity
              </option>
              <option value='500'>Upto 500</option>
              <option value='1000'>Upto 1000</option>
              <option value='5000'>Upto 5000</option>
              <option value='10000'>Upto 10,000 or more</option>
            </Select>
          </div>
          <div className='flex gap-4 items-start'>
            <label className='w-48'>Enter your post’s URL</label>
            <div className='flex-1'>
              <input
                className='w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600'
                value={postUrl}
                onChange={handleUrlChange}
              />
              {platform === 'youtube' && (
                <p className='mt-1 text-sm text-blue-500'>
                  Example : https://youtu.be/Th8JoIan4dg?si=JkYgB1RPWHnT4IR5 for
                  a Youtube Video
                </p>
              )}
            </div>
          </div>
          <div className='flex gap-4 items-center'>
            {data && data.showMessage && <p>{data.message}</p>}
          </div>
          <div className='flex justify-end gap-4'>
            {data && data.isValid && (
              <>
                <button className='btn submit' onClick={download}>
                  Download Excel file
                </button>
                <button className='btn submit' onClick={clearData}>
                  Resubmit
                </button>
              </>
            )}
            {(!data || (data && !data.isValid)) && (
              <button className='btn submit' onClick={handleSubmit}>
                Submit
              </button>
            )}
          </div>
        </div>
        <div className='flex-1 border rounded px-5 py-3'>
          <UploadTipSocial />
        </div>
      </div>
      {data && data.isValid && (
        <>
          <div className={ss['summary']}>
            <div className='flex items-center gap-5'>
              <Label>Stakeholder Category</Label>
              <Select
                className='!w-72'
                value={orderType}
                onChange={handleInputChange}
              >
                <option value={''} disabled hidden>
                  Select a stake holder
                </option>
                {StakeHolders.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
            <p>Total: {totalRows} unique rows</p>
            <p>Total: {totalTokens} tokens.</p>
            <p>Cost before tax: $ {cost}</p>
            <p>
              Process may take {totalMinutes} minutes {totalSeconds} seconds.
            </p>
          </div>
          <div className='flex gap-5'>
            <button
              className='btn submit'
              onClick={() => submitCommand(false)}
              disabled={disableButton || totalRows === 0}
            >
              Upload file to place your order now
            </button>
            <button
              className='btn submit'
              onClick={() => submitCommand(true)}
              disabled={disableButton || totalRows === 0}
            >
              Upload file to place your order to the Queue
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Social;
