import {
  IFileData,
  IData,
  Chunks,
  ISegment,
  ISegmentResponse,
} from '../model/Types';
import useGptService from './useGptService';
import { IResponse } from '@/model/Responses';

interface Topic {
  category: string;
  name: string;
  count: number;
}

interface Category {
  name: string;
  positive: number;
  negative: number;
  neutral: number;
  unknown: number;
}

interface Appreciation {
  appreciate: string;
  count: number;
}

interface CustomerBehavior {
  behavior: string;
  count: number;
}

interface Dissatisfaction {
  dissatisfaction: string;
  count: number;
}

interface Improvement {
  improvements: string;
  count: number;
}

interface Expectation {
  [key: string]: number;
}

const useDataProcess = () => {
  const {
    getTotalTokens,
    getLimitToken,
    getCommandTokens,
    getLimitTokenPerMin,
    getServerDelayFactor,
  } = useGptService();

  const BATCH_SIZE = 500;

  const processBatchTokens = async (batch: IData[]): Promise<IData[]> => {
    return await Promise.all(
      batch.map(async (item) => ({
        ...item,
        token: await getTotalTokens(item.text),
      })),
    );
  };

  const splitChunks = async (
    data: IData[],
    command?: string,
  ): Promise<Chunks[]> => {
    let newChunks: Chunks[] = [];
    let chunk: IData[] = [];
    let totalTokens = 0;

    const cmdToken = getCommandTokens(command);
    const limitTokens = getLimitToken();

    // Split data into batches of BATCH_SIZE
    let tokenCounts: IData[] = [];
    for (let i = 0; i < data.length; i += BATCH_SIZE) {
      const batch = data.slice(i, i + BATCH_SIZE);
      const processedBatch = await processBatchTokens(batch); // Process batch in parallel
      tokenCounts.push(...processedBatch);
    }

    for (const item of tokenCounts) {
      if (item.token) {
        if (totalTokens + item.token + cmdToken > limitTokens) {
          newChunks.push({ data: chunk, totalTokens: totalTokens + cmdToken });
          chunk = [item];
          totalTokens = item.token;
        } else {
          chunk.push(item);
          totalTokens += item.token;
        }
      }
    }

    if (chunk.length > 0) {
      newChunks.push({ data: chunk, totalTokens: totalTokens + cmdToken });
    }

    return newChunks;
  };

  const calculateTime = (files: IFileData[]) => {
    let _totalRows = 0;
    let _totalTokens = 0;
    let _totalMinutes = 0;
    let _totalSeconds = 0;
    files.forEach((it) => {
      if (it.isValid) {
        _totalRows += it.totalRows;
        _totalTokens += it.totalTokens;
      }
    });
    let seconds = Math.round(
      (_totalTokens / getLimitTokenPerMin()) * 60 * getServerDelayFactor(),
    );
    _totalMinutes = Math.floor(seconds / 60);
    _totalSeconds = seconds % 60;
    return {
      totalRows: _totalRows,
      totalTokens: _totalTokens,
      totalMinutes: _totalMinutes,
      totalSeconds: _totalSeconds,
    };
  };

  const analysis = (data: string[]): IResponse<ISegmentResponse> => {
    let result = {} as ISegmentResponse;
    let rawArr: string[] = [];
    let jsonArr: any[] = [];
    let errArr: { err: string; text: string }[] = [];
    data.forEach((val) => {
      try {
        rawArr.push(val);
        const jsonVal = JSON.parse(val);
        jsonArr.push(jsonVal);
      } catch (err) {
        errArr.push({ err: err as string, text: val });
      }
    });
    console.log('jsonArr', jsonArr);
    let obj = {};
    jsonArr.forEach((val) => {
      obj = deepMerge(obj, val);
    });
    result.raw = rawArr.join('\n');
    let mapType: 'OLD' | 'NEW' = 'NEW';
    if (obj['customer_appreciate'] === undefined) {
      mapType = 'OLD';
    } else {
      // refactor the object
      const mapTopicsToObject = (
        topics: Topic[],
      ): Record<string, Record<string, number>> => {
        return topics.reduce(
          (acc, topic) => {
            if (!acc[topic.category]) {
              acc[topic.category] = {};
            }
            acc[topic.category][topic.name] = topic.count;
            return acc;
          },
          {} as Record<string, Record<string, number>>,
        );
      };
      obj['topics'] = mapTopicsToObject(obj['topics']);

      const mapCategoriesToObject = (
        categories: Category[],
      ): Record<string, Omit<Category, 'name'>> => {
        return categories.reduce(
          (acc, { name, positive, negative, neutral, unknown }) => {
            acc[name] = { positive, negative, neutral, unknown };
            return acc;
          },
          {} as Record<string, Omit<Category, 'name'>>,
        );
      };
      obj['categories'] = mapCategoriesToObject(obj['categories']);

      const mapAppreciationsToObject = (
        appreciations: Appreciation[],
      ): Record<string, number> => {
        return appreciations.reduce(
          (acc, { appreciate, count }) => {
            acc[appreciate] = count;
            return acc;
          },
          {} as Record<string, number>,
        );
      };
      obj['customer_appreciate'] = mapAppreciationsToObject(
        obj['customer_appreciate'],
      );

      const mapBehaviorsToObject = (
        behaviors: CustomerBehavior[],
      ): Record<string, number> => {
        return behaviors.reduce(
          (acc, { behavior, count }) => {
            acc[behavior] = count;
            return acc;
          },
          {} as Record<string, number>,
        );
      };
      obj['customer_behaviors'] = mapBehaviorsToObject(
        obj['customer_behaviors'],
      );

      const mapDissatisfactionsToObject = (
        dissatisfactions: Dissatisfaction[],
      ): Record<string, number> => {
        return dissatisfactions.reduce(
          (acc, { dissatisfaction, count }) => {
            acc[dissatisfaction] = count;
            return acc;
          },
          {} as Record<string, number>,
        );
      };
      obj['customer_dissatisfaction'] = mapDissatisfactionsToObject(
        obj['customer_dissatisfaction'],
      );

      const mapImprovementsToObject = (
        improvements: Improvement[],
      ): Record<string, number> => {
        return improvements.reduce(
          (acc, { improvements, count }) => {
            acc[improvements] = count;
            return acc;
          },
          {} as Record<string, number>,
        );
      };
      obj['customer_improvements'] = mapImprovementsToObject(
        obj['customer_improvements'],
      );

      const mapExpectationsToObject = (
        expectations: Expectation[],
      ): Record<string, number> => {
        return expectations.reduce(
          (acc, expectation) => {
            Object.keys(expectation).forEach((key) => {
              acc[key] = expectation[key];
            });
            return acc;
          },
          {} as Record<string, number>,
        );
      };
      obj['customer_expectations'] = mapExpectationsToObject(
        obj['customer_expectations'],
      );
    }
    result.val = obj;
    let groups: { name: string; displayName: string; hasFilter: boolean }[] = [
      {
        name: 'sentiment',
        displayName: 'Sensitivity',
        hasFilter: false,
      },
      {
        name: 'categories',
        displayName: 'Sensitivity by Topics',
        hasFilter: true,
      },
      { name: 'topics', displayName: 'Feedback by Topics', hasFilter: true },
      {
        name: 'customer_appreciate',
        displayName: 'Appreciate',
        hasFilter: true,
      },
      {
        name: 'customer_behaviors',
        displayName: 'Behaviors',
        hasFilter: true,
      },
      {
        name: 'customer_dissatisfaction',
        displayName: 'Dissatisfaction',
        hasFilter: true,
      },
      {
        name: 'customer_expectations',
        displayName: 'Expectations',
        hasFilter: false,
      },
      {
        name: 'customer_improvements',
        displayName: 'Improvements',
        hasFilter: true,
      },
    ];

    result.segments = [];
    for (const value of groups) {
      const keyName =
        mapType === 'OLD' ? value.name.replace('_', ' ') : value.name;

      result.segments.push({
        ...value,
        value: obj[keyName],
        deep: getDepth(obj[keyName]),
      });
    }
    result.error = errArr;
    console.log('segments', result.segments);
    return {
      status: true,
      data: result,
    };
  };

  const getDepth = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return 0;
    }
    let maxDepth = 0;
    for (let key in obj) {
      maxDepth = Math.max(maxDepth, getDepth(obj[key]));
    }
    return maxDepth + 1;
  };

  const deepMerge = (obj1, obj2) => {
    if (typeof obj1 === 'number' && typeof obj2 === 'number') {
      return obj1 + obj2;
    }
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return obj2;
    }

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      const mergedArray = [...obj1];
      obj2.forEach((item2) => {
        const index = mergedArray.findIndex(
          (item1) => item1.name === item2.name,
        );
        if (index !== -1) {
          mergedArray[index] = deepMerge(mergedArray[index], item2);
        } else {
          mergedArray.push(item2);
        }
      });
      return mergedArray;
    }

    const merged = { ...obj1 };
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        merged[key] = deepMerge(obj1[key], obj2[key]);
      }
    }
    return merged;
  };

  const jsonToHtml = (obj, indent = 0) => {
    let html = '';
    let paddingLeft = 20 * indent;

    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        html += `<div style="margin-left: ${paddingLeft}px;font-size: 12px;text-transform: uppercase;font-weight: 700;">${key}:</div>`;
        let valsText = value.map((v) => `<div>- ${v}</div>`).join('');
        html += `<div style="margin-left: ${
          paddingLeft + 20
        }px">${valsText}</div>`;
      } else if (typeof value === 'object') {
        html += `<div style="margin-left: ${paddingLeft}px;margin-top: 0px;font-size: 12px;text-transform: uppercase;font-weight: 700;">${key}:</div>`;
        html += jsonToHtml(value, indent + 1);
      } else {
        html += `<div style="margin-left: ${paddingLeft}px">- ${key}: ${value}</div>`;
      }
    }

    return html;
  };

  const sumCategories = (data) => {
    let dt = {};
    const dataValue = data.value;
    Object.keys(dataValue).forEach((key) => {
      let total = 0;
      Object.keys(dataValue[key]).forEach((k) => {
        total += dataValue[key][k];
      });
      dt[key] = total;
    });
    return {
      ...data,
      value: dt,
    };
  };

  const getSegment = (segs: ISegment[] | undefined, name: string) => {
    const f = (segs && segs.filter((v) => v.name === name)) || {};
    return f[0];
  };

  const ObjectToFlatArray2Level = (obj: {
    [key: string]: { [key: string]: number };
  }): {
    name: string;
    value: number;
    parent?: string;
  }[] => {
    let root = { name: 'root', value: 0 };
    let index = -1;
    let flatData = Object.entries(obj).map(([key, valueObj]) => {
      const sum = Object.values(valueObj).reduce((acc, cur) => acc + cur, 0);
      index++;
      return {
        name: key,
        value: sum,
        parent: 'root',
      };
    });
    return [root, ...flatData];
  };

  const ObjectToFlatArray1Level = (obj: {
    [key: string]: { [key: string]: number };
  }): {
    name: string;
    value: number;
    parent?: string;
  }[] => {
    let root = { name: 'root', value: 0 };
    let index = -1;
    let flatData = Object.entries(obj)
      .map(([key, valueObj]) => {
        const sum: any = valueObj;
        return {
          name: key,
          value: sum,
          parent: 'root',
        };
      })
      .filter((item) => item.value !== 0);

    return [root, ...flatData];
  };

  return {
    splitChunks,
    calculateTime,
    analysis,
    sumCategories,
    getSegment,
    getDepth,
    ObjectToFlatArray2Level,
    ObjectToFlatArray1Level,
  };
};

export default useDataProcess;
