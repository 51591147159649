export interface IData {
  id: number;
  text: string;
  token?: number;
}

export interface Chunks {
  data: IData[];
  totalTokens: number;
  realTokens?: number;
}

export interface IFileData {
  id?: string;
  fileName?: string;
  chunks: Chunks[];
  totalRows: number;
  totalTokens: number;
  isValid?: boolean;
  showMessage?: boolean;
  message?: string;
  source: string;
}

export interface Props {
  command?: string;
  listFile: IFileData[];
  onFileSelected: (data: { file: IFileData; status: 'add' | 'remove' }) => void;
}

export interface IPagationResult<T> {
  totalRecord: number;
  result: T[];
}

export interface ISegment {
  name: string;
  value: object;
  deep: number;
  totalRow?: number;
  hasFilter: boolean;
}

export interface ISegmentResponse {
  raw: string;
  val: object;
  segments: ISegment[];
  error: { err: string; text: string }[];
}

export interface IFilterOption {
  isShow: boolean;
  variable: string;
}

export type IAccountRole =
  | 'Owner'
  | 'Manager'
  | 'Analyst'
  | 'Admin'
  | 'SuperAdmin'
  | '';

export type IAccountType = 'Company' | 'Personal';

export const StakeHolders = [
  { name: 'Customers', value: 'customers' },
  { name: 'Employees', value: 'employees' },
  { name: 'Vendors', value: 'vendors' },
  { name: 'Fans/Followers', value: 'fans/followers' },
  { name: 'Others', value: 'others' },
];

export type ISearchType =
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'company'
  | 'userId'
  | ''
  | 'orderId';
