import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import ss from './FileUpload.module.scss';
import * as XLSX from 'xlsx';
import { IFileData } from '../../model/Types';
import useDataProcess from '../../hook/useDataProcess';
import useGptService from '../../hook/useGptService';
import * as chardet from 'chardet';

type FileUploadProps = {
  fileList: IFileData[];
  addFileToList: (file: IFileData) => void;
  removeFileFromList: (file: IFileData) => void;
};

const generateUID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2, 15);
};

const FileUpload = ({
  fileList,
  addFileToList,
  removeFileFromList,
}: FileUploadProps) => {
  const [fileId] = useState(`file-holder-${generateUID()}`);
  const [data, setData] = useState<IFileData>();
  const { splitChunks } = useDataProcess();
  const { getLimitPerFile } = useGptService();

  useEffect(() => {
    if (data && data.isValid) {
      addFileToList(data);
    }
    if (data && data.showMessage === false && data.totalRows === 0) {
      removeFileFromList(data);
    }
  }, [data]);

  const onFileSelectedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputElement = event.target;
    const file: File | null = inputElement.files?.[0] || null;
    let dataFile: IFileData = { id: fileId, source: 'local' } as IFileData;
    if (
      file &&
      (file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    ) {
      dataFile.showMessage = false;
      setTimeout(() => {
        const reader: FileReader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async (e: any) => {
          // Convert buffer to Uint8Array
          const uint8Array = new Uint8Array(e.target.result as ArrayBuffer);

          // Detect the charset for the entire file
          const detectedEncoding = chardet.detect(uint8Array) || 'utf-8';
          console.log('Detected Encoding:', detectedEncoding);

          const data: Uint8Array = new Uint8Array(e.target.result);
          const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
          const worksheet: XLSX.WorkSheet =
            workbook.Sheets[workbook.SheetNames[0]];
          if (worksheet && worksheet['!protect']) {
            alert('The File is protected, we can’t process it');
            return;
          }
          const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            raw: false,
          });

          const sanitizeText = (text: string, encoding: string): string => {
            // Decode text using TextDecoder for additional accuracy
            const decoder = new TextDecoder(encoding);
            const decodedText = decoder.decode(new TextEncoder().encode(text));

            return decodedText
              .normalize('NFKC') // Normalize Unicode characters
              .replace(/[^\x20-\x7E]/g, '') // Remove non-ASCII printable characters
              .trim(); // Remove leading/trailing whitespace
          };

          const fData = excelData
            .filter((it) => it != null && it != '')
            .map((it, ind) => {
              return {
                id: ind,
                text: sanitizeText(it[0], detectedEncoding).toLocaleLowerCase(),
              };
            });
          const exist = fileList.filter((item) => item.fileName === file.name);
          if (exist.length === 0) {
            dataFile.fileName = file.name;
            dataFile.chunks = await splitChunks(fData);
            dataFile.chunks.forEach((v) => {
              dataFile.totalRows += v.data.length;
            });
            dataFile.showMessage = true;
            const total = getFileTotalTokens(dataFile);
            dataFile.totalTokens = total.totalTokens;
            dataFile.totalRows = total.totalRows;
            if (total.totalTokens > getLimitPerFile()) {
              dataFile.isValid = false;
              dataFile.message =
                'Because of a token limit, processing this file is not possible as it is quite large. Consider breaking it into smaller segments or uploading a more compact file.';
            } else {
              dataFile.isValid = true;
            }
          } else {
            dataFile.fileName = file.name;
            dataFile.showMessage = true;
            dataFile.isValid = false;
            dataFile.message = 'Duplicate file.';
          }
          setData(dataFile);
        };
      }, 100);
    }
  };

  const getFileTotalTokens = (dataFile: IFileData) => {
    let total = 0;
    let rows = 0;
    dataFile.chunks.forEach((chunk) => {
      total += chunk.totalTokens;
      rows += chunk.data.length;
    });
    return { totalTokens: total, totalRows: rows };
  };

  const triggerClickFile = () => {
    const fileUpload: HTMLElement | null = document.getElementById(fileId);
    fileUpload?.click();
  };

  const remove = () => {
    const fileInput = document.getElementById(fileId) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    const dataFile = {
      isValid: false,
      showMessage: false,
      totalRows: 0,
      id: fileId,
    } as IFileData;
    setData(dataFile);
  };

  return (
    <div>
      <div className={cx(ss['file-upload-wrapper'])}>
        <input
          type='text'
          className='input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600'
          value={data?.fileName || ''}
          readOnly
          onClick={triggerClickFile}
        />
        <button
          type='button'
          className='btn mx-[3px]'
          onClick={triggerClickFile}
        >
          Select File
        </button>
        {data?.showMessage && (
          <button type='button' className='btn mx-[3px]' onClick={remove}>
            Remove File
          </button>
        )}
        <input
          id={fileId}
          className={cx(ss['file-holder'])}
          type='file'
          accept='.xls,.xlsx'
          onChange={onFileSelectedHandler}
        />
      </div>
      {data?.showMessage && (
        <div className={cx(ss['file-message-details'])}>
          {data.totalRows ? <p>Detected {data.totalRows} unique rows</p> : null}
          {data.totalTokens ? <p>Detected {data.totalTokens} tokens</p> : null}
          {data.message ? (
            <p className={cx(ss['alert'])}>File is not valid. {data.message}</p>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
