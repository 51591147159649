import React, { useState } from 'react';
import ss from '../upload/Upload.module.scss';
import FilterButton from '../../atom/FilterButton';
import useExport from '@/hook/useExport';

const OrderTable = ({ segments, showFilters, setShowFilters }) => {
  const { transformToArray } = useExport();
  const cols = segments.map((seg) => {
    return transformToArray(seg);
  });
  const renderTableHeader = () => {
    let header: any[] = [];
    cols.forEach((rows, index) => {
      header.push(
        <th
          key={`hcol-${index}`}
          colSpan={rows[0].length}
          className='bg-[#d9d9d9] !border !border-black'
          style={{
            minWidth: isNaN(rows[0])
              ? `${rows[0].toString().length * 10}px`
              : '',
          }}
        >
          <div className={ss['thheader']}>
            <span>{rows[0]}</span>
            <FilterButton
              data={segments[index]}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              showFilterText={false}
            />
          </div>
        </th>,
      );
    });
    return <tr>{header}</tr>;
  };
  const renderTableBody = () => {
    let maxRows = 0;
    let rows: any[] = [];
    cols.forEach((rows) => {
      maxRows = Math.max(maxRows, rows.length);
    });
    for (let i = 1; i <= maxRows; i++) {
      let tds: any[] = [];
      let rowCol = 0;
      cols.forEach((col: any[]) => {
        let row: any[] = [];
        if (col[i] !== undefined) {
          row = [...col[i]];
        } else {
          row = new Array(col[0].length).fill(null);
        }
        row.forEach((cell: any, index: number) => {
          tds.push(
            <td
              className='px-4 py-1 capitalize'
              style={{
                fontWeight: index === 0 && row.length !== 2 ? '700' : '',
                minWidth: isNaN(cell) ? `${cell.length * 10}px` : '',
              }}
              key={`cell-${i}-${rowCol + index}`}
            >
              {cell}
            </td>,
          );
        });
        rowCol += row.length;
      });
      rows.push(<tr key={`row-${i}`}>{tds}</tr>);
    }
    return rows;
  };
  return (
    <div className={ss['wrapper-result-table']}>
      <table className={ss['table']}>
        <thead className={ss['thead-dark']}>{renderTableHeader()}</thead>
        <tbody>{cols && renderTableBody()}</tbody>
      </table>
    </div>
  );
};
export default OrderTable;
